#tetris-div {
  position: fixed;
  top:50px;
  left:10px;
}
#tetris-div p {
  text-indent:0;
  margin: -4px 0px 0px 0px;
}
.square {
  height: 08px;
  width: 08px;
  position: absolute;
  border: 1px solid shade-color($body-color, 50%);
}
.score {
  font-size: 10px;
  height: 28px;
  width: 48px;
  text-align: center;
  position: absolute;
  border: 1px solid $body-color;
  margin: 0px;
  padding: 0px;
}
.black {
  background-color: $body-color;
}
.white {
  background-color: $body-bg;
}
