// Custom.scss
// Option B: Include parts of Bootstrap

$color-mode-type: data;
$color-mode-type: media-query;

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "variables";

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// 8. Add additional custom code here

//// Theme settings
@include color-mode(light) {
  a {
    font-weight: bold;
  }
  .evilpig:first-child {
    content: $evilpig-light;
  }

  body {
   background-image: $bg-pattern-light;
   background-repeat: repeat;
  }
}
@include color-mode(dark) {
  .evilpig:first-child {
    content: $evilpig-dark;
  }

  body {
   background-image: $bg-pattern-dark;
   background-repeat: repeat;
  }
}

//// Fork awesome
$fa-font-path: "~fork-awesome/fonts";
@import "~fork-awesome/scss/fork-awesome";

@import "../tetris/tetris_evilham";

//// codehilite. Generated with:
//// pygmentize -S monokai -f html -a .codehilite > _codehilite.css
@import "codehilite";
