
// Dark
$body-bg-dark: #222;
$body-color-dark: #a5a5a5; // contrast

$link-color-dark: #d5d5d5; // body-color * 1.45
$link-hover-color-dark: #fff;
$evilpig-dark: url('../img/evilpig-dark.png');
$bg-pattern-dark: url('../img/pattern-dark.png');
$link-decoration-dark: none;

// Light
$body-bg: #eaeaea;
$body-color: #555; // contrast

$link-color: #151515; // body-color * 1.45
$link-hover-color: #000;
$evilpig-light: url('../img/evilpig-light.png');
$bg-pattern-light: url('../img/pattern-light.png');
$link-decoration: none;
$link-hover-decoration: underline;


/* dejavu-sans-latin-400-normal */
@font-face {
  font-family: 'DejaVu Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(~@fontsource/dejavu-sans/files/dejavu-sans-latin-400-normal.woff2) format('woff2'), url(~@fontsource/dejavu-sans/files/dejavu-sans-latin-400-normal.woff) format('woff');
}

/* dejavu-serif-latin-400-normal */
@font-face {
  font-family: 'DejaVu Serif';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(~@fontsource/dejavu-serif/files/dejavu-serif-latin-400-normal.woff2) format('woff2'), url(~@fontsource/dejavu-serif/files/dejavu-serif-latin-400-normal.woff) format('woff');
}

/* dejavu-mono-latin-400-normal */
@font-face {
  font-family: 'DejaVu Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(~@fontsource/dejavu-mono/files/dejavu-mono-latin-400-normal.woff2) format('woff2'), url(~@fontsource/dejavu-mono/files/dejavu-mono-latin-400-normal.woff) format('woff');
}

/* Request our fonts and keep Bootstrap's default */
$font-family-sans-serif: 'DejaVu Serif', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: 'DejaVu Mono', SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;


h1:hover, h2:hover,
.cv-section-title:hover, .cv-section .cv-tag:hover {
 color: $body-color;
}
/* End Colours */

/* Structure */

ul, ol {
 margin-left: 40px;
}
ul ul {
 margin-left: 0px;
}
li {
 margin-left: 20px;
}
html, body {
 height: 100%;
}
@media (min-width: 992px) {
div.container.h-100 {
 max-width: 820px !important;
}
}
article {
 overflow: auto;
 padding-bottom: 50px;
 padding-top: 50px;
}
/* Looks */
/* fonts */

div.page h1 {
 font-size: 30px;
 margin-bottom: 10px;
 margin-top: 20px;
}
div.page h2, .tag-title {
 font-size: 25px;
 margin-top: 20px;
 margin-bottom: 10px;
}
div.page h3 {
 font-size: 18px;
 margin-top: 15px;
 margin-bottom: 8px;
}
div.page p {
 margin-top: 5px;
 margin-bottom: 5px;
 text-indent: 40px;
}


blockquote {
 margin: 0px 20px;
 font-style: italic;
 padding: 15px;
 text-indent: 0px;
}
blockquote p:before {
 content: "«";
}
blockquote p:after {
 content: "»";
}

div.codehilite {
 overflow: auto;
}

/* Films */
#films-wrapper li {
  margin-top: 10px;
  margin-bottom: 10px;
}
